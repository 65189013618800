.player-wrapper.scroll {
    position: relative;
    padding-top: 86.13%;
    /* 646 / 750 = 0.5625 */
}

.react-player.scroll {
    position: absolute;
    top: 0;
    left: 0;
}