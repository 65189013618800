.carousel_con {
    /* border: red 1px solid; */
    text-align: center;
    z-index: 0;
}

.carousel_con .active.carousel-item h3,
.carousel_con .active.carousel-item h4 {
    opacity: 100;
    transition: opacity .5s ease-in;
}


.carousel_con .slidetext {
    position: absolute;
}

.carousel-item {
    /* margin: auto; */

}

.carousel_con img {
    width: 100%;
    height: auto;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0px 0px;
    background-color: #000;
    border: 0px solid #9d9d9d;
    border-radius: 0px;
}

.carousel-caption_con {
    position: absolute;
    text-align: right;
    margin: -30px auto;
    right: 0;
    left: 0;
    width: 85%;
    bottom: 0px;
    color: #323b45;
    font-size: 14px;
    font-weight: 700;
    /* border: greenyellow 1px solid; */
}



.carousel-caption_con h3 {
    text-align: left;
    opacity: 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    transition: opacity .5s ease-out;
}

.carousel-caption_con h4 {
    text-align: left;
    opacity: 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    transition: opacity .5s ease-out
}

@media screen and (min-width:768px) {
    .carousel-caption_con {
        /* width: 25%; */
        font-size: 16px;
    }

    .carousel_con img {
        margin: 0 auto 0 0px;
        border: 0px solid #323b45;
        width: 100%;
    }

}

@media screen and (min-width: 320px) {
    .carousel_con .slidetext {
        top: -255px;
        left: 0;
    }
}

@media screen and (min-width: 360px) {
    .carousel_con .slidetext {
        top: -280px;
    }
}

@media screen and (min-width: 375px) {
    .carousel_con .slidetext {
        top: -300px;
        left: 0;
    }
}

@media screen and (min-width: 411px) {
    .carousel_con .slidetext {
        top: -345px;
        left: 0;
    }
}

@media screen and (min-width: 768px) {
    .carousel_con .slidetext {
        top: -680px;
        left: 0;
    }
}

@media screen and (min-width: 1024px) {
    .carousel_con .slidetext {
        top: -860px;
        left: 0;
    }

    .carousel_con img {
        margin: 0 auto 0 0px;
        width: 100%;
    }

    .panel img.image--devices {
        width: 70vh;
    }
}

.carousel-indicators {
    bottom: -45px;
}